<template>
  <main-wrapper
    manage
    :style="{overflowY:isAnimating?'hidden':'auto'}"
    :tool="!$route.meta.noTool"
    tool-background-color="#409EFF"
    tool-color="#FFFFFF"
    border-left-color="#409EFF"
  >

    <template slot="tool">
      <span class="ipx-1">{{title}}</span>
    </template>

    <div class="v-wrapper">
      <div class="v-nav" v-if="!$route.meta.noTree">
        <tree-user
          inner-tool
          :checkbox="false"
          :show-object-count.sync="showObjectCount"
          @click="setCurrentHold">
        </tree-user>
      </div>
      <div class="v-body">
        <keep-alive :include="cachedViews">
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>

  </main-wrapper>
</template>

<script>
import MainWrapper from '@/components/main-wrapper'
import TreeUser from '@/views/common/tree-user'
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'layoutTree',
  components: {
    TreeUser,
    MainWrapper
  },
  computed: {
    ...mapGetters([
      'currentHoldId',
      'currentHoldName',
      'cachedViews'
    ])
  },
  data() {
    return {
      title: '',
      isAnimating: false,
      showObjectCount: false
    }
  },
  watch: {
    $route() {
      this.updateName()
    }
  },
  methods: {
    updateName() {
      console.log(this.$route)
      if (this.$route.meta.title) {
        this.title = this.$route.meta.title
      }
    },
    setCurrentHold(holdID, name) {
      this.setCurrentHoldId(holdID)
      this.setCurrentHoldName(name)
    },
    ...mapMutations({
      setCurrentHoldId: 'SET_CURRENT_HOLD_ID',
      setCurrentHoldName: 'SET_CURRENT_HOLD_NAME'
    })
  },
  created() {
    this.updateName()
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/mixin.scss";
@include v-wrapper;
</style>
